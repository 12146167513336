var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg elevation-0",
    attrs: {
      "width": "600"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 primary white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('resetPassword.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "resetPasswordForm",
    attrs: {
      "id": "reset-password-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.reset.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.email'),
      "type": "email",
      "prepend-icon": _vm.icons.mdiEmail,
      "rules": [_vm.isRequired(_vm.emailInput, _vm.$t('form.field.email')), _vm.isEmail(_vm.emailInput, _vm.$t('form.field.email'))],
      "outlined": ""
    },
    model: {
      value: _vm.emailInput,
      callback: function callback($$v) {
        _vm.emailInput = $$v;
      },
      expression: "emailInput"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "small": "",
      "text": "",
      "to": "/login"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.login')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "form": "reset-password-form",
      "loading": _vm.isResetting
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.resetPassword')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }