
import { defineComponent, ref } from '@vue/composition-api'
import { mdiEmail } from '@mdi/js'

import { useNotify } from '@/store'

import { authApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired, isEmail } from '@/utils/validation'

export default defineComponent({
  name: 'ResetPasswordView',
  props: {
    email: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup: (props, { root }) => {
    const { resetPassword } = authApi.useResetPassword()

    const { addNotification } = useNotify()

    const resetPasswordForm = ref<ValidationForm | null>(null)

    const emailInput = ref(props.email)

    const isResetting = ref(false)

    const reset = () => {
      if (resetPasswordForm.value?.validate()) {
        isResetting.value = true

        resetPassword(emailInput.value)
          .then(() => {
            addNotification({ text: root.$t('resetPassword.success') as string, type: 'success' })
            root.$router.push('/login')
          })
          .catch((error) => {
            error.userMessage = root.$t('resetPassword.error')
            throw error
          })
          .finally(() => {
            isResetting.value = false
          })
      }
    }

    return {
      icons: { mdiEmail },
      resetPasswordForm,
      isRequired,
      isEmail,
      emailInput,
      isResetting,
      reset,
    }
  },
})
